import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2022/nass/1.jpg';
import blogd2 from '../../assets/images/2022/nass/2.jpg';
import blogd3 from '../../assets/images/2022/nass/3.jpg';
import blogd4 from '../../assets/images/2022/nass/4.jpg';
import blogd5 from '../../assets/images/2022/nass/5.jpg';
import blogd6 from '../../assets/images/2022/nass/6.jpg';
import blogd7 from '../../assets/images/2022/nass/7.jpg';
import blogd8 from '../../assets/images/2022/nass/8.jpg';
import blogd9 from '../../assets/images/2022/nass/9.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Federal House of Representatives Health Committee Commends
                  LUTH on Capital Projects Execution
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Federal House of Representatives House Committee Members
                  on Health, visited the Lagos University Teaching Hospital on
                  Monday July 18th, 2022; as part of their oversight functions,
                  and scored the Management high in Capital Projects Execution,
                  saying projects were judiciously executed. The visiting
                  National Assembly members also pledged to come up with a
                  policy that would tackle brain-drain that has become a
                  recurring decimal in the nation’s health institutions.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Federal House of Representatives Health Committee
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Briefing journalists shortly after a tour of the capital
                  projects at LUTH as part of their oversight duties according
                  to the 1999 constitution, the Chairman, House Committee on
                  Health Institutions, Hon. Pascal Chigozie Obi said the
                  facilities inspected at the hospital were a great improvement
                  from what they saw during their first visit to the hospital.
                  He recalled: “I want to thank the President of Nigeria,
                  Mohammadu Buhari for responding to the call we made when we
                  assume office. The hospital was not in a very good state when
                  we assume office in 2019 and after the facility tour; we came
                  back and made a report which we submitted to the presidency.
                  We also made our recommendations to the Federal government and
                  they responded by increasing the budgetary allocations to
                  these institutions by more than 250 per cent. This is why we
                  are having these positive changes in our institutions and LUTH
                  is one of the best tertiary institutions we have in the
                  country today.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  At the visitation
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  “When we came last year, we saw the independent power plant
                  which is supplying 100 per cent electricity to the facility
                  and also cancer centre that is very operational and about the
                  best in West Africa. “Today, we have seen the ICU which is
                  nearing completion, we saw the completed dialysis centre,
                  Labour ward, children surgical ward, and we saw the health
                  information management system which was built through IGR.
                  They are doing very well to the best of their ability. The
                  projects have been done well and funds used judiciously.” he
                  stated.
                  <br></br>
                  The Chairman responded to the Management of the Hospital’s
                  appeal on shortage of Staff, Obi lamented the migration of
                  Nigerian medical workers overseas stating that the issue of
                  brain drain has become a recurring decimal in the history of
                  health institutions. Stating that they have been brainstorming
                  at the policy level on how to tackle the problem, he disclosed
                  that soon they will be coming up with legislation to take care
                  of it. He condemned the idea of Nigeria losing its trained
                  skilled health professionals to other countries, adding that
                  the legislation will help curtail the pulls from outside. “We
                  will look at brain-drain very seriously if we continue at this
                  level it will be dangerous for the country.”
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  inspections
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Earlier, the Chief Medical Director of LUTH, Prof Chris Bode
                  said they have continued to make sustained and transformative
                  progress despite the challenges posed by the COVID-19
                  pandemic. He listed some of the projects executed to include;
                  the commissioning of Lion Isaac Olusola Dada Dialysis Centre
                  and Renal Institute, Commissioning of A block, rehabilitation
                  of labour ward/new neonatal unit, expansion of ICU, donation
                  of two fully equipped surgical operation suites, construction
                  of hospital information management system, among others. Bode
                  who was represented by the Chairman, Medical Advisory
                  Committee, CMAC, Prof Wasiu Adeyemo further identified some of
                  the challenges of the hospital including the acute shortage of
                  manpower, adding that the last year has been exacerbated by
                  difficulties experienced in the process for replacement of
                  staff who had left the services of the hospital by
                  resignation, retirement, and death. He said the process of
                  posting House Officers which have been taken over by the
                  Federal Government through the Medical and Dental Council of
                  Nigeria appeared to have been beset with various lapses.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd8} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
